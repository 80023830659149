<template>
  <div>
    <b-row>
      <b-col
        v-for="(item, index) in youtubeList"
        :key="index"
        lg="4"
      >
        <b-card>
          <div class="">
            <youtube
              :video-id="item"
              fit-parent
            />
          </div>
          <div class="text-center">
            <b-row>
              <b-col lg="4">
                <b-button
                  variant="flat-primary"
                  tag="a"
                  class="btn-wishlist"
                >
                  <feather-icon
                    icon="HeartIcon"
                    class="mr-50"
                  />
                  <span>いいね</span>
                </b-button>
              </b-col>
              <b-col lg="8">
                <b-button
                  variant="flat-primary"
                  tag="a"
                  class="btn-cart"
                >
                  <feather-icon
                    icon="ShoppingCartIcon"
                    class="mr-50"
                  />
                  <small>お気に入りリストに入れる</small>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- <swiper
      class="swiper-responsive-breakpoints"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(item,index) in youtubeList"
        :key="index"
      >
        <youtube
          :video-id="item"
          fit-parent
        />
      </swiper-slide>

      <div
        slot="pagination"
        class="swiper-pagination"
      />
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
    </swiper> -->
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    // VueYoutube,
    // Swiper,
    // SwiperSlide,
  },
  directives: {
    Ripple,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      youtubeList: [
        'bDHObXuNg-I',
        'cg_kcaymWK0',
        'iEK9kSHmdgo',
        'qJhyK_Kpg3k',
        'a_2Rntbn_KQ',
        'yN1GRMI5Bxw',
        'KfDTuNyup9Y',
        'qQnfwvmZmxg',
        'saDSf3cKS0M',
        'udALPK4pBDI',
      ],
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  methods: {
    detail(id) {
      this.$router.push({ path: '/lesson', query: { id } })
    },
    show() {
      window.console.log('🐶')
    },
  },
}
</script>

<style scoped>
.title_ {
  font-size: 1.3rem;
  font-weight: 500;
}
.title_:hover {
  color: rgb(65, 56, 155);
}
.youtube {
  width: 40%;
}
</style>
