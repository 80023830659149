<template>
  <div>
    <h2>ダッシュボード(受講生･求職者)</h2>
    <b-row class="match-height">
      <b-col lg="6">
        <CardAnalyticSupportTracker />
      </b-col>
      <b-col lg="6">
        <CardAnalyticSalesRadarChart />
      </b-col>
      <b-col lg="12">
        <CardAnalyticSaleLineChart />
      </b-col>
      <b-col lg="12">
        <h2 class="mb-2">
          おすすめ講座
        </h2>
        <LessonList />
      </b-col>
      <b-col lg="12">
        <h2 class="mb-2">
          おすすめ動画
        </h2>
        <TmpList />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import CardAnalyticSupportTracker from '@/components/ui/analytics/CardAnalyticSupportTracker.vue'
import CardAnalyticSalesRadarChart from '@/components/ui/analytics/CardAnalyticSalesRadarChart.vue'
import CardAnalyticSaleLineChart from '@/components/ui/analytics/CardAnalyticSaleLineChart.vue'
import LessonList from '@/pages/basic/lesson/LessonList.vue'
import TmpList from '@/components/basic/lesson/TmpList.vue'

export default {
  components: {
    BRow,
    BCol,
    CardAnalyticSupportTracker,
    CardAnalyticSalesRadarChart,
    CardAnalyticSaleLineChart,
    LessonList,
    TmpList,
  },
  directives: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
